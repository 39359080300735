@import "./variables.module";

html,
body {
  padding: 0;
  margin: 0;
  font-family: Roboto, sans-serif;
  background-color: $body-background-color;
  scrollbar-width: 16px;
  scrollbar-color: #002e52 #000f1a;

  &.hide_scrollbars {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &::-webkit-scrollbar {
    background-color: #000f1a;
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background-color: #000f1a;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #002e52;
    border-radius: 16px;
    border: 5px solid #000f1a;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #002e52;
    border: 4px solid #000f1a;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

*,
*::after,
*::before {
  box-sizing: border-box;
  font-family: inherit;
  margin: 0;
  padding: 0;
  outline: none;
  scrollbar-width: 16px;
  scrollbar-color: #002e52 #000f1a;
}

*::-webkit-scrollbar {
  background-color: #000f1a;
  width: 16px;
}
*::-webkit-scrollbar-track {
  background-color: #000f1a;
}
*::-webkit-scrollbar-thumb {
  background-color: #002e52;
  border-radius: 16px;
  border: 5px solid #000f1a;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #002e52;
  border: 4px solid #000f1a;
}
*::-webkit-scrollbar-button {
  display: none;
}

*:active,
*:focus {
  outline: none;
}

body::before {
  content: "";
  display: block;
  height: 100vh;
  width: 100%;
  background: $background-gradient;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center top;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  @include media("<tablet") {
    background-size: 200% 100%;
  }
}

body > div {
  z-index: 1;
  position: relative;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  font-family: Roboto, sans-serif;
}

html {
  font-size: 62.5%;
  
  &.car-mode {
    font-size: 62.5%; //10px
  }

  @include media(">=desktop2560") {
    font-size: 70%;
  }

  @include media(">=desktop3840") {
    font-size: 100%;
  }

  @include media("<=desktop720p") {
    font-size: 50%;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

html,
body {
  &.modal_open {
    /* when modal active */
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    /* Other browsers */
    overscroll-behavior: none;
  }
}

.faded {
  opacity: 0.4;
}

.loader {
  padding: 4.8rem 3.2rem;
  display: flex;
  justify-content: center;

  &.fullpage {
    width: 100%;
    height: 100vh;
    align-items: center;

    svg {
      width: 6.4rem;
      height: 6.4rem;
    }
  }

  svg {
    width: 4.8rem;
    height: 4.8rem;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

// VLYBY
.vlyby_container_main {
  position: fixed;
  bottom: 3.2rem;
  right: 3.2rem;
  z-index: 1;
  width: 40rem;
  
  @include media("<tablet") {
    right: 1.6rem;
    width: 24rem;
    bottom: 10rem; 
    max-width: 50vh;
    
    &.vlyby_mini_player_active {
      bottom: 17rem;
    }
  }
  @include media("<phoneXL") {
    width: 18rem;
  }
  
  .vlyby_container_main_inner {
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;
  }
  .vlyby_container_main_content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  iframe {
    border: none;
  }
}
